import React from "react"
import { GatsbyImage, getSrc } from "gatsby-plugin-image"
import { motion } from 'framer-motion'
import { fadeBump, fade } from "../helpers/transitionHelper"
import { graphql } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import SEO from "../components/seo"

const JournalTemplatePage = ({ data }) => {
  const { name, releaseDate, coverImage, details, label } = data.contentfulAlbum;
  return <>
    <SEO
      title={name}
      image={getSrc(coverImage)}
    />

    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
      className="pb-24 md:pb-32 pt-6 md:pt-12 overflow-hidden relative"
      data-scroll-section
    >
      <div className="w-full">
        <div className="relative">
          <div className="w-full md:w-10/12 md:pl-24 lg:w-10/12 lg:pl-16 xl:pl-32 xl:w-11/12 md:ml-auto relative">
            <motion.div variants={fade} className="relative overflow-hidden">

                <GatsbyImage
                  image={coverImage.gatsbyImageData}
                  objectFit="contain"
                  objectPosition="left"
                  className="w-full h-full image-opacity-in"
                  alt={coverImage.title} />

            </motion.div>

            <motion.div variants={fadeBump} className="w-full md:w-8/12 xl:w-8/12 mr-auto mb-10 md:mb-5vw xl:mb-4vw px-4 md:px-0 text-left">
              <h1 className="text-10xlvw md:text-xlvw font-display relative leading-none border-b border-transparent tracking-tighter mb-3 pb-0 md:pr-12 mt-3 md:mt-5">
                {name} {"|"} {releaseDate} &bull; {label}
              </h1>
            </motion.div>
          </div>


          <motion.div variants={fade} className="px-4 md:px-12">

                      <div className="w-10/12 md:w-10/12 md:pl-16 lg:w-10/12 lg:pl-8 xl:pl-20 xl:w-11/12 md:ml-auto relative mb-8 md:mb-12 xl:mb-16">
                        <div className="w-full md:w-9/12 xl:pl-2">
                          <div className="content max-w-xl">
                            {renderRichText(details)}
                          </div>
                        </div>
                      </div>

          </motion.div>
        </div> 
      </div>
    </motion.section>
  </>;
}

export const query = graphql`
    query ContentFulAlbum($slug: String, $language: String) {
        contentfulAlbum(slug: { eq: $slug }, node_locale: { eq: $language }) {
            slug
            name
            label
            releaseDate(formatString: "YYYY")
            details {
                raw
            }
            coverImage {
                title
                description
                gatsbyImageData(layout: CONSTRAINED)
            }
        }
    }
`;
export default JournalTemplatePage